<template>
  <div>
    <p class="p-text-title">Información general</p>
    <skeleton-general-information-component v-if="bSkeleton" />

    <div v-else class="content-general-information">
      <div class="centered-image">
        <div class="content-img">
          <img :src="oImageRawMaterial" />
        </div>
      </div>
      <v-container fluid>
        <v-row class="content-information">
          <v-col cols="12" sm="12" md="3" lg="3" xl="3">
            <p class="p-title">Clave</p>
            <p class="p-text">{{ oRawMaterial.sCode }}</p>
          </v-col>
          <v-col cols="12" sm="12" md="5" lg="5" xl="5">
            <p class="p-title">Nombre</p>
            <p class="p-text">{{ oRawMaterial.sName }}</p>
          </v-col>
          <v-col class="content-color" cols="12" sm="12" md="4" lg="4" xl="4">
            <p class="p-title">Color</p>
            <v-chip class="text-color" :color="'#' + oRawMaterial.sColorKey">
            </v-chip>
          </v-col>
        </v-row>
      </v-container>
      <div class="content-btn">
        <v-btn v-show="bAdminMaterial" class="global-btn-primary btn-modify" @click="setDialogEditInformation">Modificar
        </v-btn>
      </div>
    </div>

    <v-divider class="divider-global mt-9"></v-divider>
    <dialog-edit-information-quantity-component @setDialogEditInformation="setDialogEditInformation"
      :bDialogEditInformation="bDialogEditInformation" :itemRawMaterial="oRawMaterial" />
  </div>
</template>
<script>
export default {
  data() {
    return {
      bSkeleton: true,
      oRawMaterial: {},
      oImageRawMaterial: {},
      bDialogEditInformation: false,
    };
  },
  beforeMount() {
    this.getGeneralInformationById();
  },
  destroyed() {
    this.$store.commit("setsNameDetail", "");
  },
  methods: {
    setDialogEditInformation() {
      this.bDialogEditInformation = !this.bDialogEditInformation;
    },
    getGeneralInformationById() {
      const payload = {},
        config = {
          headers: {
            Authorization: "Bearer " + this.$store.state.sToken,
          },
        };
      DB.get(
        `${URI}/api/sp/v1/raw-materials/${this.$route.params.id}`,
        config,
        payload
      )
        .then((response) => {
          this.oRawMaterial = response.data.results;
          this.$emit("setBasePurchasePriceMXN",this.oRawMaterial.oPrices.oPurchase.dMexicanPeso);
          this.$emit("setBasePurchasePriceUSD",this.oRawMaterial.oPrices.oPurchase.dUSDollar);
          this.$emit("setBaseSalePriceMXN",this.oRawMaterial.oPrices.oSales.dMexicanPeso);
          this.$emit("setBaseSalePriceUSD",this.oRawMaterial.oPrices.oSales.dUSDollar);
          this.$store.commit("setsNameDetail", this.oRawMaterial.sName);
          this.oImageRawMaterial = Object.keys(response.data.results.oImages).length !== 0 ? response.data.results.oImages.md : require("@/assets/Empty_Img.png");
          this.bSkeleton = false;
          this.$nextTick(() => {
            let img1 = document.getElementById("img1");
          });
          this.$store.commit("refresher", false);
        })
        .catch((error) => {
          this.Error(error.response.data);
        });
    },

  },
  computed: {
    refreshTable() {
      return this.$store.state.refresh;
    },
    bShowMaterial() {
      return this.$store.state.bShowMaterial;
    },
    bAdminMaterial() {
      return this.$store.state.bAdminMaterial;
    },
  },
  watch: {
    refreshTable: function () {
      this.getGeneralInformationById();
    },
  },
};
</script>
<style scoped>
.content-general-information {
  display: flex;
  width: 100%;
}

.p-text-title {
  color: var(--primary-color-color-title-information);
  font-family: "pop-Bold";
  font-size: 14px;
  letter-spacing: 4.8px;
  opacity: 1;
}

.content-information {
  align-items: center;
  display: flex;
}

.p-title {
  color: var(--primary-color-text);
  margin-bottom: 0px;
  font-family: "pop-Regular";
  font-size: 14px;
}

.p-text {
  color: var(--primary-color-text);
  margin-bottom: 0px;
  margin-left: 10px;
  font-family: "pop-Regular";
  font-size: 14px;
}

.text-color {
  /* -webkit-text-stroke: .5px black; */
  color: var(--primary-color-text) !important;
  margin-bottom: 0px;
  width: 50%;
  border: solid var(--primary-color-text) 1px !important;
  /* margin-left: 10px; */
  font-family: "pop-Regular" !important;
  font-size: 14px !important;
}

.content-img {
  display: flex;
  justify-content: center;
  margin-right: 10px;
  height: 100px;
  width: 100px;
}

.content-img img {
  width: 100%;
  height: auto;
}

@supports (object-fit: cover) {
  .content-img img {
    height: 100%;
    object-fit: cover;
    object-position: center center;
    border-radius: 20px;
  }
}

.content-btn {
  width: 170px;
  align-self: center;
}

/**************************MODO RESPONSIVO ********************/
/* XS */
@media (max-width: 599px) {
  .demoColorResponsive {
    background-color: rgb(190, 136, 226);
  }

  .content-general-information {
    display: block;
    width: 100%;
  }

  .centered-image {
    display: flex;
    justify-content: center !important;
    text-align-last: center !important;
    margin-right: 0px;
    margin-bottom: 15px;
  }

  .content-information {
    margin-bottom: 10px;
  }

  .p-text-title {
    text-align: center;
  }

  .p-title {
    text-align: center;
  }

  .p-text {
    text-align: center;
    margin-left: 0px;
  }

  .content-color {
    text-align: -webkit-center;
  }

  .content-btn {
    width: 100%;
  }
}

/* SM */
@media (min-width: 600px) and (max-width: 959px) {
  .demoColorResponsive {
    background-color: rgb(136, 226, 151);
  }
}

/* MD */
@media (min-width: 960px) and (max-width: 1264px) {
  .demoColorResponsive {
    background-color: rgb(201, 122, 83);
  }
}

/* LG */
@media (min-width: 1264px) and (max-width: 1904px) {
  .demoColorResponsive {
    background-color: rgb(204, 198, 109);
  }
}

/* XL */
@media (min-width: 1904px) {
  .demoColorResponsive {
    background-color: rgb(113, 199, 201);
  }
}
</style>